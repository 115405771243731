const dev = process.env.NODE_ENV !== 'production'

declare global {
  interface Window {
    dataLayer: any
  }
}

export const shouldNotTrack = () => dev || typeof window === 'undefined' || !window.dataLayer

export const logPageView = (params: any) => {
  if (shouldNotTrack()) {
    console.log('not tracking page view', params)
    return
  }

  window?.dataLayer?.push({
    event: 'pageView',
    path: window.location.pathname,
    ...params,
  })
}

export const logEvent = (name = '', properties = {}) => {
  if (shouldNotTrack()) {
    console.log('not tracking event', name, properties)
    return
  }

  if (name) {
    window?.dataLayer?.push({
      event: name,
      ...properties,
    })
  }
}
